import React from 'react';
import {
  ListBox as AriaListBox,
  ListBoxItem as AriaListBoxItem,
  type ListBoxProps as AriaListBoxProps,
  Collection,
  Header,
  type ListBoxItemProps,
  ListBoxSection,
  type SectionProps,
  composeRenderProps,
} from 'react-aria-components';
import { tv } from 'tailwind-variants';

import { composeTailwindRenderProps, focusRing } from './component-utils';
import { CheckSVG } from 'components/iconsSVG/check';
import { getDomainValue } from 'utils/domain';
import { LabTickIcon } from 'components/camperlab/icons/tick';

interface ListBoxProps<T> extends Omit<AriaListBoxProps<T>, 'layout' | 'orientation'> {}

export function ListBox<T extends object>({ children, ...props }: ListBoxProps<T>) {
  return (
    <AriaListBox {...props} className={composeTailwindRenderProps(props.className, 'border border-dark p-1 outline-0')}>
      {children}
    </AriaListBox>
  );
}

export const itemStyles = tv({
  extend: focusRing,
  base: 'group relative flex cursor-default select-none items-center gap-8 px-2.5 py-1.5 text-sm will-change-transform forced-color-adjust-none lg:text-xs',
  variants: {
    isSelected: {
      false: '-outline-offset-2 hover:bg-subtle',
      true: 'outline-white bg-neutral-inverted text-inverted -outline-offset-4 forced-colors:bg-[Highlight] forced-colors:text-[HighlightText] forced-colors:outline-[HighlightText]',
    },
    isDisabled: {
      true: 'text-disabled forced-colors:text-[GrayText]',
    },
  },
});

export function ListBoxItem(props: ListBoxItemProps) {
  const textValue = props.textValue || (typeof props.children === 'string' ? props.children : undefined);
  return (
    <AriaListBoxItem {...props} textValue={textValue} className={itemStyles}>
      {composeRenderProps(props.children, (children) => (
        <>
          {children}
          <div className="absolute bottom-0 left-4 right-4 hidden h-px bg-neutral/20 forced-colors:bg-[HighlightText] [.group[data-selected]:has(+[data-selected])_&]:block" />
        </>
      ))}
    </AriaListBoxItem>
  );
}

export const dropdownItemStyles = tv({
  base: 'group flex cursor-pointer select-none items-center gap-4 p-1.5 outline outline-0 forced-color-adjust-none lab:text-sm md:py-1 lab:lg:text-xs',
  variants: {
    isDisabled: {
      false: '',
      true: 'text-disabled forced-colors:text-[GrayText]',
    },
    isFocused: {
      true: 'bg-neutral-inverted !text-inverted lab:bg-neutral lab:!text-copy forced-colors:bg-[Highlight] forced-colors:text-[HighlightText]',
    },
  },
  compoundVariants: [
    {
      isFocused: false,
      isOpen: true,
      className: 'bg-subtle',
    },
  ],
});

export function DropdownItem(props: ListBoxItemProps) {
  const textValue = props.textValue || (typeof props.children === 'string' ? props.children : undefined);
  return (
    <AriaListBoxItem {...props} textValue={textValue} className={dropdownItemStyles}>
      {composeRenderProps(props.children, (children, { isSelected, isFocused }) => (
        <>
          <span className="flex flex-1 items-center gap-2 truncate lab:font-bold">{children}</span>
          <span className="flex w-5 items-center justify-end">
            {(
              getDomainValue({
                camper: isSelected,
                nnormal: isSelected,
                camperlab: isSelected || isFocused,
              })
            ) ?
              getDomainValue({ camper: <CheckSVG className="size-3.5" />, nnormal: 'camper', camperlab: <LabTickIcon /> })
            : null}
          </span>
        </>
      ))}
    </AriaListBoxItem>
  );
}

export interface DropdownSectionProps<T> extends SectionProps<T> {
  title?: string;
}

export function DropdownSection<T extends object>(props: DropdownSectionProps<T>) {
  return (
    <ListBoxSection className="after:block after:h-[5px] after:content-[''] first:-mt-[5px]">
      <Header className="sticky -top-[5px] z-10 -mx-1 -mt-px truncate border-b px-4 py-1 text-xs font-semibold uppercase text-copy supports-[-moz-appearance:none]:bg-subtle [&+*]:mt-1">
        {props.title}
      </Header>
      <Collection items={props.items}>{props.children}</Collection>
    </ListBoxSection>
  );
}
